import React from 'react';
import Header from "../components/Header/Header";
import {Flex, Image} from "antd";
import Banner from "../Home/components/Banner/Banner";
import StatsSection from "../Home/components/StatsSection/StatsSection";
import PartnersSection from "../Home/components/PartnersSection/PartnersSection";
import Footer from "../components/Footer/Footer";
import {clrs} from "../../constants/colors";
import {useWindowSize} from "@uidotdev/usehooks";
import MyText from "../../components/UI/Text/MyText";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import dilara from './dilara.png';
import gaziza from './gaziza.png';
import salta from './salta.png';
import batyrbek from './batyrbek.png';
import madina from './madina.jpg';
import {Fade} from "react-awesome-reveal";
import NewBanner from "../Home/components/Banner/NewBanner";

const AboutUsPage = () => {
    const size = useWindowSize();

    return (
        <div>
            <Header/>
            <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}} align={"center"} justify={"center"} vertical>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical align={"flex-start"} justify={"flex-start"}>
                    <NewBanner/>
                    <Flex style={{width: "100%", position: "relative", paddingTop: 50}} align={"center"} justify={"center"} vertical gap={35}>
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                            <MyText size={"larger"} strong>
                                <IntlMessage id={'aboutUsPage.aboutCentre'}/>
                            </MyText>
                            <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                <IntlMessage id={'aboutUsPage.description'}/>
                            </MyText>
                        </Fade>

                    </Flex>
                    <Flex style={{width: "100%", position: "relative", paddingTop: 50, paddingBottom: 50}} align={"center"} justify={"center"} vertical gap={35}>
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                            <MyText size={"larger"} strong style={{textAlign: "center"}}>
                                <IntlMessage id={'aboutUsPage.ourTeam'}/>
                            </MyText>
                        </Fade>

                    </Flex>
                </Flex>
            </Flex>
            {
                size.width > 700 ?
                    <>
                        <Flex style={{width: "100%", position: "relative", backgroundColor: "#EFEFEF"}} align={"center"} justify={"center"} vertical>
                            <Flex style={{width: "100%", position: "relative", padding: "40px 20px 40px 20px"}} align={"center"} justify={"center"} vertical>
                                <Flex gap={50} style={{width: "100%", maxWidth: 800, position: "relative"}}
                                      align={"center"} justify={"space-between"}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"left"}>
                                        <div style={{
                                            width: 223,
                                            height: 300,
                                            overflow: "hidden",
                                            borderRadius: 20,
                                            // border: "1px solid",
                                            position: "relative"
                                        }}>
                                            <img src={dilara} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>
                                        </div>
                                    </Fade>

                                    <Flex
                                        style={{width: "calc(100% - 223px - 50px)", position: "relative", height: 300}}
                                        vertical align={"flex-start"} justify={"center"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                                            <MyText strong uppercase size={"bigger"}>
                                                <IntlMessage id={'aboutUsPage.dilara'}/>
                                            </MyText>
                                            <MyText strong>
                                                <IntlMessage id={'aboutUsPage.director'}/>
                                            </MyText>
                                            <MyText>
                                                Tel: +7 (7172) 645 714 (вн. 2124)
                                            </MyText>
                                            <MyText>
                                                Email: dilara.abzhanova@astanait.edu.kz
                                            </MyText>
                                            <MyText>
                                                Room: С2. — 1.35
                                            </MyText>
                                        </Fade>

                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex style={{width: "100%", position: "relative", backgroundColor: "#ffffff"}} align={"center"} justify={"center"} vertical>
                            <Flex style={{width: "100%", position: "relative", padding: "40px 20px 40px 20px"}} align={"center"} justify={"center"} vertical>
                                <Flex gap={50} style={{width: "100%", maxWidth: 800, position: "relative"}}
                                      align={"center"} justify={"space-between"}>
                                    <Flex style={{width: "calc(100% - 223px - 50px)", position: "relative", height: 300}} vertical
                                          align={"flex-start"} justify={"center"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                                            <MyText strong uppercase size={"bigger"}>
                                                <IntlMessage id={'aboutUsPage.madina'}/>
                                            </MyText>
                                            <MyText strong>
                                                <IntlMessage id={'aboutUsPage.mainManager'}/>
                                            </MyText>
                                            <MyText>
                                                Tel: +7 (7172) 645 714 (вн. 3059)
                                            </MyText>
                                            <MyText>
                                                Email: madina.akhmetova@astanait.edu.kz
                                            </MyText>
                                            <MyText>
                                                Room: С2. — 1.36
                                            </MyText>
                                        </Fade>

                                    </Flex>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"right"}>
                                        <div style={{
                                            width: 223,
                                            height: 300,
                                            overflow: "hidden",
                                            borderRadius: 20,
                                            // border: "1px solid",
                                            position: "relative"
                                        }}>
                                            <img src={madina} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>
                                        </div>
                                    </Fade>

                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex style={{width: "100%", position: "relative", backgroundColor: "#EFEFEF"}} align={"center"}
                              justify={"center"} vertical>
                            <Flex style={{width: "100%", position: "relative", padding: "40px 20px 40px 20px"}}
                                  align={"center"} justify={"center"} vertical>
                                <Flex gap={50} style={{width: "100%", maxWidth: 800, position: "relative"}}
                                      align={"center"} justify={"space-between"}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"left"}>
                                        <div style={{
                                            width: 223,
                                            height: 300,
                                            overflow: "hidden",
                                            borderRadius: 20,
                                            // border: "1px solid",
                                            position: "relative"
                                        }}>
                                            <img src={salta} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>
                                        </div>
                                    </Fade>

                                    <Flex
                                        style={{width: "calc(100% - 223px - 50px)", position: "relative", height: 300}}
                                        vertical align={"flex-start"} justify={"center"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                                            <MyText strong uppercase size={"bigger"}>
                                                <IntlMessage id={'aboutUsPage.salta'}/>
                                            </MyText>
                                            <MyText strong>
                                                <IntlMessage id={'aboutUsPage.manager'}/>
                                            </MyText>
                                            <MyText>
                                                Tel: +7 (7172) 645 714 (вн. 2123)
                                            </MyText>
                                            <MyText>
                                                Email: saltanat.sharipova@astanait.edu.kz
                                            </MyText>
                                            <MyText>
                                                Room: С2. — 1.36
                                            </MyText>
                                        </Fade>

                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex style={{width: "100%", position: "relative", backgroundColor: "#ffffff"}} align={"center"} justify={"center"} vertical>
                            <Flex style={{width: "100%", position: "relative", padding: "40px 20px 40px 20px"}} align={"center"} justify={"center"} vertical>
                                <Flex gap={50} style={{width: "100%", maxWidth: 800, position: "relative"}}
                                      align={"center"} justify={"space-between"}>
                                    <Flex style={{width: "calc(100% - 223px - 50px)", position: "relative", height: 300}} vertical
                                          align={"flex-start"} justify={"center"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                                            <MyText strong uppercase size={"bigger"}>
                                                <IntlMessage id={'aboutUsPage.gaziza'}/>
                                            </MyText>
                                            <MyText strong>
                                                <IntlMessage id={'aboutUsPage.specialist'}/>
                                            </MyText>
                                            <MyText>
                                                Tel: +7 (7172) 645 714 (вн. 3034)
                                            </MyText>
                                            <MyText>
                                                Email: gaziza.nametova@astanait.edu.kz
                                            </MyText>
                                            <MyText>
                                                Room: С2. — 1.36
                                            </MyText>
                                        </Fade>

                                    </Flex>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"right"}>
                                        <div style={{
                                            width: 223,
                                            height: 300,
                                            overflow: "hidden",
                                            borderRadius: 20,
                                            // border: "1px solid",
                                            position: "relative"
                                        }}>
                                            <img src={gaziza} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>
                                        </div>
                                    </Fade>

                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex style={{width: "100%", position: "relative", backgroundColor: "#EFEFEF"}} align={"center"}
                              justify={"center"} vertical>
                            <Flex style={{width: "100%", position: "relative", padding: "40px 20px 40px 20px"}}
                                  align={"center"} justify={"center"} vertical>
                                <Flex gap={50} style={{width: "100%", maxWidth: 800, position: "relative"}}
                                      align={"center"} justify={"space-between"}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"left"}>
                                        <div style={{
                                            width: 223,
                                            height: 300,
                                            overflow: "hidden",
                                            borderRadius: 20,
                                            // border: "1px solid",
                                            position: "relative"
                                        }}>
                                            <img src={batyrbek} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>
                                        </div>
                                    </Fade>

                                    <Flex
                                        style={{width: "calc(100% - 223px - 50px)", position: "relative", height: 300}}
                                        vertical align={"flex-start"} justify={"center"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                                            <MyText strong uppercase size={"bigger"}>
                                                <IntlMessage id={'aboutUsPage.batyrbek'}/>
                                            </MyText>
                                            <MyText strong>
                                                <IntlMessage id={'aboutUsPage.specialist'}/>
                                            </MyText>
                                            <MyText>
                                                Tel: +7 (7172) 645 714 (вн. 3034)
                                            </MyText>
                                            <MyText>
                                                Email: batyrbek.bakytkereiuly@astanait.edu.kz
                                            </MyText>
                                            <MyText>
                                                Room: С2. — 1.36
                                            </MyText>
                                        </Fade>

                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex style={{width: "100%", position: "relative", backgroundColor: "#ffffff"}} align={"center"} justify={"center"} vertical>
                            <Flex style={{width: "100%", position: "relative", padding: "40px 20px 40px 20px"}} align={"center"} justify={"center"} vertical>
                                <Flex gap={50} style={{width: "100%", maxWidth: 800, position: "relative"}}
                                      align={"center"} justify={"space-between"}>
                                    <Flex style={{width: "calc(100% - 223px - 50px)", position: "relative", height: 300}} vertical
                                          align={"flex-start"} justify={"center"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                                            <MyText strong uppercase size={"bigger"}>
                                                <IntlMessage id={'aboutUsPage.dias'}/>
                                            </MyText>
                                            <MyText strong>
                                                <IntlMessage id={'aboutUsPage.specialist'}/>
                                            </MyText>
                                            <MyText>
                                                Tel: +7 (7172) 645 714 (вн. 3034)
                                            </MyText>
                                            <MyText>
                                                Email: dias.utebayev@astanait.edu.kz
                                            </MyText>
                                            <MyText>
                                                Room: С2. — 1.36
                                            </MyText>
                                        </Fade>
                                    </Flex>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"right"}>
                                        <div style={{
                                            width: 223,
                                            height: 300,
                                            overflow: "hidden",
                                            borderRadius: 20,
                                            // border: "1px solid",
                                            position: "relative"
                                        }}>
                                            {/*<img src={d} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>*/}
                                        </div>
                                    </Fade>

                                </Flex>
                            </Flex>
                        </Flex>
                    </>
                    :
                    <>
                        <Flex style={{width: "100%", position: "relative", backgroundColor: "#EFEFEF"}} align={"center"}
                              justify={"center"} vertical>
                            <Flex style={{width: "100%", position: "relative", padding: "40px 20px 40px 20px"}}
                                  align={"center"} justify={"center"} vertical>
                                <Flex gap={50} style={{width: "100%", maxWidth: 800, position: "relative"}}
                                      align={"center"} justify={"space-between"} vertical>
                                    <Fade triggerOnce={true} cascade damping={0.15}>
                                        <div style={{
                                            width: 223,
                                            height: 300,
                                            overflow: "hidden",
                                            borderRadius: 20,
                                            // border: "1px solid",
                                            position: "relative"
                                        }}>
                                            <img src={dilara} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>
                                        </div>
                                    </Fade>

                                    <Flex style={{width: "100%", position: "relative", textAlign: "center"}} vertical
                                          align={"center"} justify={"center"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                                            <MyText strong uppercase size={"bigger"}>
                                                <IntlMessage id={'aboutUsPage.dilara'}/>
                                            </MyText>
                                            <MyText strong>
                                                <IntlMessage id={'aboutUsPage.director'}/>
                                            </MyText>
                                            <MyText>
                                                Tel: +7 (7172) 645 714 (вн. 2124)
                                            </MyText>
                                            <MyText>
                                                Email: dilara.abzhanova@astanait.edu.kz
                                            </MyText>
                                            <MyText>
                                                Room: С2. — 1.35
                                            </MyText>
                                        </Fade>

                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex style={{width: "100%", position: "relative", backgroundColor: "#ffffff"}} align={"center"} justify={"center"} vertical>
                            <Flex style={{width: "100%", position: "relative", padding: "40px 20px 40px 20px"}} align={"center"} justify={"center"} vertical>
                                <Flex gap={50} style={{width: "100%", maxWidth: 800, position: "relative"}}
                                      align={"center"} justify={"space-between"} vertical>
                                    <Fade triggerOnce={true} cascade damping={0.15}>
                                        <div style={{
                                            width: 223,
                                            height: 300,
                                            overflow: "hidden",
                                            borderRadius: 20,
                                            // border: "1px solid",
                                            position: "relative"
                                        }}>
                                            <img src={madina} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>
                                        </div>
                                    </Fade>

                                    <Flex
                                        style={{width: "100%", position: "relative", textAlign: "center"}}
                                        vertical
                                        align={"center"} justify={"center"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                                            <MyText strong uppercase size={"bigger"}>
                                                <IntlMessage id={'aboutUsPage.madina'}/>
                                            </MyText>
                                            <MyText strong>
                                                <IntlMessage id={'aboutUsPage.mainManager'}/>
                                            </MyText>
                                            <MyText>
                                                Tel: +7 (7172) 645 714 (вн. 3059)
                                            </MyText>
                                            <MyText>
                                                Email: madina.akhmetova@astanait.edu.kz
                                            </MyText>
                                            <MyText>
                                                Room: С2. — 1.36
                                            </MyText>
                                        </Fade>

                                    </Flex>

                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex style={{width: "100%", position: "relative", backgroundColor: "#EFEFEF"}} align={"center"} justify={"center"} vertical>
                            <Flex style={{width: "100%", position: "relative", padding: "40px 20px 40px 20px"}}
                                  align={"center"} justify={"center"} vertical>
                                <Flex gap={50} style={{width: "100%", maxWidth: 800, position: "relative"}}
                                      align={"center"} justify={"space-between"} vertical>
                                    <Fade triggerOnce={true} cascade damping={0.15}>
                                        <div style={{
                                            width: 223,
                                            height: 300,
                                            overflow: "hidden",
                                            borderRadius: 20,
                                            // border: "1px solid",
                                            position: "relative"
                                        }}>
                                            <img src={salta} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>
                                        </div>
                                    </Fade>

                                    <Flex style={{width: "100%", position: "relative", textAlign: "center"}} vertical
                                          align={"center"} justify={"center"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                                            <MyText strong uppercase size={"bigger"}>
                                                <IntlMessage id={'aboutUsPage.salta'}/>
                                            </MyText>
                                            <MyText strong>
                                                <IntlMessage id={'aboutUsPage.manager'}/>
                                            </MyText>
                                            <MyText>
                                                Tel: +7 (7172) 645 714 (вн. 2123)
                                            </MyText>
                                            <MyText>
                                                Email: saltanat.sharipova@astanait.edu.kz
                                            </MyText>
                                            <MyText>
                                                Room: С2. — 1.36
                                            </MyText>
                                        </Fade>

                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex style={{width: "100%", position: "relative", backgroundColor: "#ffffff"}} align={"center"} justify={"center"} vertical>
                            <Flex style={{width: "100%", position: "relative", padding: "40px 20px 40px 20px"}} align={"center"} justify={"center"} vertical>
                                <Flex gap={50} style={{width: "100%", maxWidth: 800, position: "relative"}}
                                      align={"center"} justify={"space-between"} vertical>
                                    <Fade triggerOnce={true} cascade damping={0.15}>
                                        <div style={{
                                            width: 223,
                                            height: 300,
                                            overflow: "hidden",
                                            borderRadius: 20,
                                            // border: "1px solid",
                                            position: "relative"
                                        }}>
                                            <img src={gaziza} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>
                                        </div>
                                    </Fade>

                                    <Flex
                                        style={{width: "100%", position: "relative", textAlign: "center"}}
                                        vertical
                                        align={"center"} justify={"center"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                                            <MyText strong uppercase size={"bigger"}>
                                                <IntlMessage id={'aboutUsPage.gaziza'}/>
                                            </MyText>
                                            <MyText strong>
                                                <IntlMessage id={'aboutUsPage.specialist'}/>
                                            </MyText>
                                            <MyText>
                                                Tel: +7 (7172) 645 714 (вн. 3034)
                                            </MyText>
                                            <MyText>
                                                Email: gaziza.nametova@astanait.edu.kz
                                            </MyText>
                                            <MyText>
                                                Room: С2. — 1.36
                                            </MyText>
                                        </Fade>

                                    </Flex>

                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex style={{width: "100%", position: "relative", backgroundColor: "#EFEFEF"}} align={"center"} justify={"center"} vertical>
                            <Flex style={{width: "100%", position: "relative", padding: "40px 20px 40px 20px"}}
                                  align={"center"} justify={"center"} vertical>
                                <Flex gap={50} style={{width: "100%", maxWidth: 800, position: "relative"}}
                                      align={"center"} justify={"space-between"} vertical>
                                    <Fade triggerOnce={true} cascade damping={0.15}>
                                        <div style={{
                                            width: 223,
                                            height: 300,
                                            overflow: "hidden",
                                            borderRadius: 20,
                                            // border: "1px solid",
                                            position: "relative"
                                        }}>
                                            <img src={batyrbek} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>
                                        </div>
                                    </Fade>

                                    <Flex style={{width: "100%", position: "relative", textAlign: "center"}} vertical
                                          align={"center"} justify={"center"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                                            <MyText strong uppercase size={"bigger"}>
                                                <IntlMessage id={'aboutUsPage.batyrbek'}/>
                                            </MyText>
                                            <MyText strong>
                                                <IntlMessage id={'aboutUsPage.specialist'}/>
                                            </MyText>
                                            <MyText>
                                                Tel: +7 (7172) 645 714 (вн. 3034)
                                            </MyText>
                                            <MyText>
                                                Email: batyrbek.bakytkereiuly@astanait.edu.kz
                                            </MyText>
                                            <MyText>
                                                Room: С2. — 1.36
                                            </MyText>
                                        </Fade>

                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex style={{width: "100%", position: "relative", backgroundColor: "#ffffff"}} align={"center"} justify={"center"} vertical>
                            <Flex style={{width: "100%", position: "relative", padding: "40px 20px 40px 20px"}} align={"center"} justify={"center"} vertical>
                                <Flex gap={50} style={{width: "100%", maxWidth: 800, position: "relative"}}
                                      align={"center"} justify={"space-between"} vertical>
                                    <Fade triggerOnce={true} cascade damping={0.15}>
                                        <div style={{
                                            width: 223,
                                            height: 300,
                                            overflow: "hidden",
                                            borderRadius: 20,
                                            // border: "1px solid",
                                            position: "relative"
                                        }}>
                                            {/*<img src={d} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>*/}
                                        </div>
                                    </Fade>

                                    <Flex
                                        style={{width: "100%", position: "relative", textAlign: "center"}}
                                        vertical
                                        align={"center"} justify={"center"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                                            <MyText strong uppercase size={"bigger"}>
                                                <IntlMessage id={'aboutUsPage.dias'}/>
                                            </MyText>
                                            <MyText strong>
                                                <IntlMessage id={'aboutUsPage.specialist'}/>
                                            </MyText>
                                            <MyText>
                                                Tel: +7 (7172) 645 714 (вн. 3034)
                                            </MyText>
                                            <MyText>
                                                Email: dias.utebayev@astanait.edu.kz
                                            </MyText>
                                            <MyText>
                                                Room: С2. — 1.36
                                            </MyText>
                                        </Fade>

                                    </Flex>

                                </Flex>
                            </Flex>
                        </Flex>
                    </>
            }

            <Footer/>
        </div>
    );
};

export default AboutUsPage;