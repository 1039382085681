import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import ContractService from "../../services/ContractService";

let initialState = {
    contracts: [],
    isLoading: false,
    hasMore: false,
    error: null,
}
export const getAllContract = createAsyncThunk(
    'getAllContract',
    async ({ page, limit, name, categoryId }, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await ContractService.getAllContractsPageable(page, limit, name, categoryId);
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const contractListSlice = createSlice({
    name: 'contractListSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllContract.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllContract.fulfilled, (state, action) => {
                state.isLoading = false;
                state.contracts = action.payload.data;
                state.hasMore = action.payload.hasMore;
            })
            .addCase(getAllContract.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.contracts = [];
                state.hasMore = false;
            })
    }
});

export const {} = contractListSlice.actions;

export default contractListSlice.reducer;