import React, {useEffect, useState} from 'react';
import {Checkbox, Flex, Form, Radio, Select} from "antd";
// import cl from "../../components/Header/Header.module.css";
import classes from './Calculation.module.css';
import BlockLoading from "../../components/LoadingComponents/BlockLoading";
import FormItem from "antd/es/form/FormItem";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import {useTranslation} from "react-i18next";
import MyText from "../../components/UI/Text/MyText";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import CategoryService from "../../services/CategoryService";
import {LocalName} from "../../utils/LocalName";
import CalculationService from "../../services/CalculationService";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import {clrs} from "../../constants/colors";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";

const CalculationPage = () => {

    const {t} = useTranslation();

    const [form] = Form.useForm();

    const [categoriesList, setCategoriesList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryCourses, setCategoryCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingResult, setLoadingResult] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isTransh, setIsTransh] = useState(false);
    const [transhCount, setTranshCount] = useState(2);

    const category = Form.useWatch('category', form);
    const courses = Form.useWatch('courses', form);
    const discount = Form.useWatch('discount', form);

    const [result, setResult] = useState();


    useEffect(() => {
        CategoryService.getAllCategories()
            .then((r) => {
                setLoading(false);
                setCategoriesList(r.data);
            })
            .catch(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        setCategories(
            categoriesList.map(category => {
                return {
                    value: category.id,
                    label: LocalName.getName(category),
                    data: {id: category.id, courses: category.courses}
                };
            })
        );
    }, [categoriesList]);

    useEffect(() => {
        setLoadingResult(true);
        const handler = setTimeout(() => {
            if (category && courses && totalPrice && transhCount) {
                CalculationService.getCoursesPrice(
                    totalPrice,
                    discount ? discount : 0,
                    isTransh ? transhCount : 1
                )
                    .then((r) => {
                        setResult(r.data);
                    })
                    .catch((error) => {
                        console.error('Ошибка при запросе данных:', error);
                    })
                    .finally(() => {
                        setLoadingResult(false); // Обязательно отключаем загрузку
                    });
            } else {
                setLoadingResult(false); // Если условие не выполнено, отключаем загрузку
            }
        }, 1500);
        return () => {
            clearTimeout(handler);
        };
    }, [category, courses, discount, transhCount, isTransh, totalPrice]);


    const handleTotalPrice = (checkedValues) => {
        const total = checkedValues.reduce((sum, course) => sum + course.price, 0);
        setTotalPrice(total);
    };

    return (
        <div>
            <Header/>
            <Flex gap={"middle"} style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}}
                  align={"center"}
                  justify={"center"} vertical>
                {/*<MyText size={"large"} uppercase strong><IntlMessage id={'calculation'}/></MyText>*/}
                <Flex gap={50} style={{width: "100%", maxWidth: 1200, position: "relative"}}
                      align={"center"} justify={"center"} vertical>
                    <Form form={form} className={classes.form} style={{padding: 0}}>
                        <BlockLoading isLoading={loading}/>
                        <div style={{
                            backgroundColor: clrs.blackerBlue,
                            width: "100%",
                            padding: "20px 20px",
                            borderTopRightRadius: "10px",
                            borderTopLeftRadius: "10px",
                            color: clrs.white
                        }}>
                            <MyText uppercase strong size={"bigger"}><IntlMessage id={'calculation'}/></MyText>
                        </div>
                        <Flex vertical align={"flex-start"} justify={"flex-start"} gap={15}
                              style={{padding: "0 20px 20px", width: '100%'}}>
                            <FormItem rules={[{
                                required: true,
                                message: <IntlMessage id={'requiredField'}/>,
                            },
                            ]} label={<IntlMessage id={'discount'}/>} labelCol={{span: 24}}
                                      className={classes.formInput} className={classes.formInputBlock} name="category">
                                <Select
                                    options={categories}
                                    placeholder={<IntlMessage id={'categoryCourse'}/>}
                                    onChange={(value) => {
                                        const selectedCategory = categories.find(cat => cat.value === value);
                                        setCategoryCourses(selectedCategory?.data?.courses || []);
                                    }}
                                />
                            </FormItem>
                            {categoryCourses.length > 0 &&
                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'requiredField'}/>,
                                },
                                ]} label={t('courses')}
                                          labelCol={{span: 24}} className={classes.formInputBlock} name="courses">
                                    <Checkbox.Group
                                        style={{
                                            width: '100%',
                                        }}
                                        onChange={handleTotalPrice}
                                    >
                                        <Flex align={"flex-start"} justify={"flex-start"} wrap={"wrap"} gap={20}
                                              style={{width: "100%"}}>
                                            {
                                                categoryCourses.map((course, i) => {
                                                    return (
                                                        <Checkbox key={course.id}
                                                                  value={course}>{LocalName.getName(course)}</Checkbox>
                                                    )
                                                })
                                            }

                                        </Flex>
                                    </Checkbox.Group>
                                </FormItem>
                            }
                            <FormItem rules={[{
                                required: true,
                                message: <IntlMessage id={'requiredField'}/>,
                            },
                            ]} label={<IntlMessage id={'discount'}/>} labelCol={{span: 24}}
                                      className={classes.formInputBlock} name="discount">
                                <Select
                                    defaultValue={{
                                        key: 'none',
                                        value: 0,
                                        label: 'Не выбрано',
                                    }}
                                    rootClassName={classes.formSelect}
                                    options={[
                                        {
                                            key: 'none',
                                            value: 0,
                                            label: 'Не выбрано',
                                        },
                                        {
                                            key: 'family_disabled',
                                            value: 20,
                                            label: 'Представители семей, в которых оба родителя являются лицами с ограниченными физическими возможностями или пенсионерами',
                                        },
                                        {
                                            key: 'large_family',
                                            value: 20,
                                            label: 'Представители многодетных семей, воспитывающих 4 и более несовершеннолетних детей',
                                        },
                                        {
                                            key: 'orphans',
                                            value: 50,
                                            label: "Лица, оставшиеся без попечительства родителей, сиротами, лицами с ограниченными возможностями"
                                        }
                                    ]}
                                />
                            </FormItem>
                            {/*<Checkbox onChange={() => {*/}
                            {/*    setIsTransh(!isTransh)*/}
                            {/*}} defaultChecked={false}>*/}
                            {/*    <IntlMessage id={'isTransh'}/>*/}
                            {/*</Checkbox>*/}
                            {/*<Flex gap={"small"}>*/}
                            {/*    <IntlMessage id={'payByOne'}/>*/}
                            {/*    <Switch defaultChecked={false} onChange={() => {*/}
                            {/*        setIsTransh(!isTransh)*/}
                            {/*    }}/>*/}
                            {/*    <IntlMessage id={'payByTransh'}/>*/}
                            {/*</Flex>*/}
                            <ToggleButtonGroup
                                value={isTransh}
                                exclusive
                                color='primary'
                                onChange={() => {
                                    setIsTransh(!isTransh)
                                }}
                            >
                                <ToggleButton size="small" value={false} style={{border: '1px solid #1677ff', fontSize: '0.7rem', textTransform: "none" }}>
                                    <IntlMessage id={'payByOne'}/>
                                </ToggleButton>
                                <ToggleButton size="small" value={true} style={{border: '1px solid #1677ff', fontSize: '0.7rem', textTransform: "none"}}>
                                    <IntlMessage id={'payByTransh'}/>
                                </ToggleButton>
                            </ToggleButtonGroup>
                            {isTransh &&
                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'requiredField'}/>,
                                },
                                ]} label={<IntlMessage id={'discount'}/>} labelCol={{span: 24}}
                                          className={classes.formInputBlock} name="transh">
                                    {/*<Slider*/}
                                    {/*    min={2}*/}
                                    {/*    max={3}*/}
                                    {/*    value={transhCount}*/}
                                    {/*    onChange={(value) => setTranshCount(value)}*/}
                                    {/*/>*/}
                                    <Radio.Group
                                        block
                                        defaultValue={transhCount}
                                        onChange={(e) => setTranshCount(e.target.value)}
                                        optionType="button"
                                        buttonStyle="solid"
                                    >
                                        <Radio value={2}>2</Radio>
                                        <Radio value={3}>3</Radio>
                                    </Radio.Group>

                                </FormItem>
                            }
                        </Flex>
                    </Form>
                    <Flex className={classes.form} style={{padding: 0}}>
                        <BlockLoading isLoading={loadingResult}/>
                        <div style={{
                            backgroundColor: clrs.blackerBlue,
                            width: "100%",
                            padding: "20px 20px",
                            borderTopRightRadius: "10px",
                            borderTopLeftRadius: "10px", color: clrs.white
                        }}>
                            <MyText uppercase strong size={"bigger"}><IntlMessage id={'result'}/></MyText>
                        </div>
                        <Flex vertical align={"flex-start"} justify={"flex-start"} gap={15}
                              style={{padding: "0 20px 20px", width: '100%'}}>
                            {isTransh &&
                                result?.transhPrices.map((transh, i) => {
                                    return (
                                        <>
                                            <Flex key={i} gap={"small"} style={{width: '100%'}}>
                                                <IntlMessage id={'transh'}/>
                                                {i + 1}:
                                                <div style={{flex: 1, borderBottom: "1px solid #d9d9d9"}}></div>
                                                {transh}
                                                <IntlMessage id={'tenge'}/>
                                            </Flex>
                                        </>
                                    )
                                })
                            }
                            <Flex style={{width: '100%'}} gap={"small"}
                                  justify={isTransh ? "space-between" : "flex-start"}>
                                <MyText size={"bigger"} strong><IntlMessage id={'totalPrice'}/></MyText>
                                <div style={{flex: 1, borderBottom: "1px solid #d9d9d9"}}></div>
                                <MyText size={"bigger"} strong>{result?.totalPrice} <IntlMessage id={'tenge'}/></MyText>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Footer/>
        </div>
    );
};


export default CalculationPage;