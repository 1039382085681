import React, {useState} from 'react';
import {Button, Flex} from "antd";
import MyText from "../../components/UI/Text/MyText";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import {useParams} from "react-router-dom";
import {API_BASE_URL} from "../../constants/api";
import {Document, Page, pdfjs} from 'react-pdf';
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import BlockLoading from "../../components/LoadingComponents/BlockLoading";
import GreyButton from "../../components/UI/Buttons/GreyButton";
import DocumentService from "../../services/DocumentService";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const DocumentPage = () => {
    const {documentId} = useParams();
    const [loading, setLoading] = useState(true);
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({numPages}: { numPages: number }): void {
        setNumPages(numPages);
        setLoading(false);
    }

    async function downloadDocument() {
        await DocumentService.getDocumentById(documentId)
            .then((rs) => {
                // console.log(rs)
                window.open(API_BASE_URL + "/api/generator/contract?formId=" + rs.data.form.id, '_blank');
            })
            .catch(() => {

            })
    }

    return (
        <>
            <Header/>
            <BlockLoading isLoading={loading}/>
            <Flex gap={50}
                  style={{
                      width: "100%",
                      minHeight: "calc(100vh - 300px)",
                      position: "relative",
                      padding: "120px 20px 20px 20px",
                  }}
                  vertical
                  align={"flex-start"}
                  justify={"flex-start"}>
                <Flex align={"center"} justify={"center"} vertical
                      style={{overflowX: "auto", overflowY: "hidden", width: "100%"}}>
                    <Flex style={{
                        zIndex: 1,
                        padding: "10px 20px",
                        backgroundColor: "white",
                        borderRadius: 10,
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    }} gap={15} justify={"space-between"} align={"center"}>
                        <Button disabled={pageNumber === 1} onClick={() => {
                            setPageNumber(pageNumber - 1)
                        }}>
                            {'<'}
                        </Button>
                        <MyText size={"small"}>
                            {pageNumber} / {numPages}
                        </MyText>
                        <Button disabled={pageNumber === numPages} onClick={() => {
                            setPageNumber(pageNumber + 1)
                        }}>
                            {'>'}
                        </Button>
                    </Flex>
                    <Document file={API_BASE_URL + "/api/document/resource/" + documentId}
                              onLoadSuccess={onDocumentLoadSuccess}
                              loading={<IntlMessage id={'documentIsLoading'}/>}
                    >
                        <Page pageNumber={pageNumber} width={700}/>
                    </Document>
                </Flex>
                <Flex align={"flex-start"} justify={"center"} gap={20} style={{width: "100%"}}>
                    <GreyButton onClick={() => downloadDocument()}>
                        <IntlMessage id={'downloadContract'}/>
                    </GreyButton>
                </Flex>
            </Flex>
            <Footer/>
        </>
    );
};

export default DocumentPage;