import React, {useState} from 'react';
import {Flex, Form, Input, message, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import TeacherService from "../../../../../services/TeacherService";
import classes from "../../Courses/CreateCourse/CreateCourse.module.css";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import FormItem from "antd/es/form/FormItem";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";
import {clrs} from "../../../../../constants/colors";
import UserService from "../../../../../services/UserService";

const CreateUsers = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const firstName = Form.useWatch('firstName', form);
    const lastName = Form.useWatch('lastName', form);
    const email = Form.useWatch('email', form);
    const password = Form.useWatch('password', form);
    const role = Form.useWatch('role', form);

    const [isLoadingCreate, setIsLoadingCreate] = useState(false);

    function handleCreate() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setIsLoadingCreate(true);
                await UserService.saveUser(firstName, lastName, email, password, role)
                    .then(() => {
                        message.destroy();
                        message.success(<IntlMessage id={'creation'}/>, 5);
                        form.resetFields();
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                    })
                    .finally(() => {
                        setIsLoadingCreate(false);
                    })
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    return (
        <Form form={form} className={classes.form}>
            <BlockLoading isLoading={isLoadingCreate}/>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'firstName'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="firstName">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "firstName"})} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'lastName'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="lastName">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "lastName"})} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'email'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="email">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "email"})} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'password'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="password">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "password"})} />
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInput} style={{width: '100%', padding: 0}} name="role"
                      label={<IntlMessage id={'role'}/>} labelCol={{ span: 24 }}
            >
                <Select
                    options={[
                    { value: 'teacher', label: <IntlMessage id={"ordersPage.teacher"}/> },
                    { value: 'student', label: <IntlMessage id={"student"}/> }
                    ]}
                    placeholder={IntlMessageText.getText({id: "role"})}/>
            </FormItem>
            <FormItem className={classes.formInputBlock}>
                <Flex align={"flex-start"} justify={"flex-start"} gap={20} style={{width: "100%"}}>
                    <GreyButton onClick={handleCreate} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}><IntlMessage id={'create'}/></GreyButton>
                </Flex>
            </FormItem>
        </Form>
    );
};

export default CreateUsers;