import React from 'react';
import Header from "../components/Header/Header";
import {Col, Flex, Image, Row} from "antd";
import PartnersSection from "../Home/components/PartnersSection/PartnersSection";
import Footer from "../components/Footer/Footer";
import {useWindowSize} from "@uidotdev/usehooks";
import {motion} from "framer-motion";
import img1 from '../../images/1с.png';
import img2 from '../../images/amazon.png';
import img3 from '../../images/apple.png';
import exec1 from '../../images/exec1.png';
import exec2 from '../../images/exec2.png';
import exec3 from '../../images/exec3.png';
import teacher from '../../images/yp.png';
import styles from './mba.module.css'
import {clrs} from "../../constants/colors";
import {CalendarOutlined} from "@ant-design/icons";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import MyText from "../../components/UI/Text/MyText";
import Banner from "../Home/components/Banner/Banner";

const MbaPage = () => {
    const size = useWindowSize();

    return (
        <div>
            <Header/>
            <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}} align={"center"}
                  justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    <Banner/>
                    <Flex justify={"space-between"} vertical={size.width <= 1024} style={{width: "100%", backgroundColor: "white"}}>
                        <Flex vertical>
                            <motion.h1
                                initial={{x: -100, opacity: 0}}
                                whileInView={{x: 0, opacity: 1}}
                                viewport={{once: true, amount: 0.8}}
                                transition={{delay: 0.3, type: 'spring', stiffness: 80}}
                                style={{
                                    alignSelf: size.width <= 1024 ? "center" : "normal"
                                }}
                            >
                                <MyText stronger size={"larger"} style={{color: clrs.blackerBlue}}>Executive
                                    MBA</MyText>
                            </motion.h1>
                            <motion.h1
                                initial={{x: -100, opacity: 0}}
                                whileInView={{x: 0, opacity: 1}}
                                viewport={{once: true, amount: 0.8}}
                                transition={{delay: 0.3, type: 'spring', stiffness: 80}}
                                style={{
                                    alignSelf: size.width <= 1024 ? "center" : "normal"
                                }}
                            >
                                <MyText stronger size={"larger"}>«Управление цифровой трансформацией»</MyText>
                            </motion.h1>
                            <motion.h3
                                initial={{x: -100, opacity: 0}}
                                whileInView={{x: 0, opacity: 1}}
                                viewport={{once: true, amount: 0.8}}
                                transition={{delay: 0.6, type: 'spring', stiffness: 80}}
                            >
                                <MyText medium size={"bigger"}>совместно с Санкт-Петербургским Международным
                                    Институтом
                                    Менеджмента (ИМИСП)</MyText>
                            </motion.h3>
                        </Flex>
                        <Flex align={"center"} gap={20} vertical={size.width <= 1024}>
                            <motion.div
                                initial={{x: 100, opacity: 0}}
                                whileInView={{opacity: 1, x: 0}}
                                viewport={{once: true, amount: 0.8}}
                                transition={{delay: 0.3, type: 'spring', stiffness: 80}}
                            >
                                <img src={img1} alt="" width={100}/>
                            </motion.div>
                            <motion.div
                                initial={{x: 100, opacity: 0}}
                                whileInView={{opacity: 1, x: 0}}
                                viewport={{once: true, amount: 0.8}}
                                transition={{delay: 0.6, type: 'spring', stiffness: 80}}
                            >
                                <img src={img2} alt="" width={100}/>
                            </motion.div>
                            <motion.div
                                initial={{opacity: 0}}
                                whileInView={{opacity: 1}}
                                viewport={{once: true, amount: 0.8}}
                                transition={{delay: 0.9, type: 'spring', stiffness: 80}}
                            >
                                <img src={img3} alt="" width={100}/>
                            </motion.div>
                        </Flex>
                    </Flex>
                    <Flex gap={16} vertical={size.width <= 481} wrap={size.width > 481} style={{width: "100%"}}>
                        <motion.div
                            initial={{opacity: 0, y: 100}}
                            whileInView={{opacity: 1, y: 0}}
                            viewport={{once: true}}
                            transition={{delay: 0.3, type: 'spring', stiffness: 80}}

                            className={styles.box}>
                            <motion.h4
                                initial={{y: 100, opacity: 0}}
                                whileInView={{y: 0, opacity: 1}}
                                viewport={{once: true}}
                                transition={{delay: 0.3, type: 'spring', stiffness: 80}}
                            >
                                <CalendarOutlined style={{fontSize: 24, color: clrs.white, marginRight: 10}}/>
                                <MyText uppercase><IntlMessage id={'trainDuration'}/></MyText>
                            </motion.h4>
                            <motion.h3
                                initial={{y: 60, opacity: 0}}
                                whileInView={{y: 0, opacity: 1}}
                                viewport={{once: true}}
                                transition={{delay: 0.5, type: 'spring', stiffness: 80}}
                            >
                                <MyText>18 <IntlMessage id={"months"}/></MyText>
                            </motion.h3>
                        </motion.div>
                        <motion.div
                            initial={{opacity: 0, y: 100}}
                            whileInView={{opacity: 1, y: 0}}
                            viewport={{once: true}}
                            transition={{delay: 0.3, type: 'spring', stiffness: 80}}

                            className={styles.box}>
                            <motion.h4
                                initial={{y: 100, opacity: 0}}
                                whileInView={{y: 0, opacity: 1}}
                                viewport={{once: true}}
                                transition={{delay: 0.3, type: 'spring', stiffness: 80}}
                            >
                                <CalendarOutlined style={{fontSize: 24, color: clrs.white, marginRight: 10}}/>
                                <MyText uppercase><IntlMessage id={'trainFormat'}/></MyText>
                            </motion.h4>
                            <motion.h3
                                initial={{y: 60, opacity: 0}}
                                whileInView={{y: 0, opacity: 1}}
                                viewport={{once: true}}
                                transition={{delay: 0.5, type: 'spring', stiffness: 80}}
                            >
                                <MyText><IntlMessage id={"modular"}/></MyText>
                            </motion.h3>
                        </motion.div>
                        <motion.div
                            initial={{opacity: 0, y: 100}}
                            whileInView={{opacity: 1, y: 0}}
                            viewport={{once: true}}
                            transition={{delay: 0.3, type: 'spring', stiffness: 80}}

                            className={styles.box}>
                            <motion.h4
                                initial={{y: 100, opacity: 0}}
                                whileInView={{y: 0, opacity: 1}}
                                viewport={{once: true}}
                                transition={{delay: 0.3, type: 'spring', stiffness: 80}}
                            >
                                <CalendarOutlined style={{fontSize: 24, color: clrs.white, marginRight: 10}}/>
                                <MyText uppercase><IntlMessage id={'trainLanguage'}/></MyText>
                            </motion.h4>
                            <motion.h3
                                initial={{y: 60, opacity: 0}}
                                whileInView={{y: 0, opacity: 1}}
                                viewport={{once: true}}
                                transition={{delay: 0.5, type: 'spring', stiffness: 80}}
                            ><MyText><IntlMessage id={"russian"}/></MyText>
                            </motion.h3>
                        </motion.div>
                        <motion.div
                            initial={{opacity: 0, y: 100}}
                            whileInView={{opacity: 1, y: 0}}
                            viewport={{once: true}}
                            transition={{delay: 0.3, type: 'spring', stiffness: 80}}

                            className={styles.box}>
                            <motion.h4
                                initial={{y: 100, opacity: 0}}
                                whileInView={{y: 0, opacity: 1}}
                                viewport={{once: true}}
                                transition={{delay: 0.3, type: 'spring', stiffness: 80}}
                            >
                                <CalendarOutlined style={{fontSize: 24, color: clrs.white, marginRight: 10}}/>
                                <MyText uppercase><IntlMessage id={'classSchedule'}/></MyText>
                            </motion.h4>
                            <motion.h3
                                initial={{y: 60, opacity: 0}}
                                whileInView={{y: 0, opacity: 1}}
                                viewport={{once: true}}
                                transition={{delay: 0.5, type: 'spring', stiffness: 80}}
                            ><MyText><IntlMessage id={"classScheduleTime"}/></MyText>
                            </motion.h3>
                        </motion.div>
                    </Flex>
                    <Flex vertical justify={"space-between"} align={"center"} gap={25}
                          style={{width: "100%", position: "relative"}}>
                        <motion.p
                            initial={{scale: 0}}
                            viewport={{once: true, amount: 0.8}}
                            whileInView={{scale: 1}}
                            transition={{type: 'spring', stiffness: 50, delay: 0.2}}
                            className='title' style={{margin: 0}}>
                            <p className={styles.sectionTitleText}>Структура программы</p>
                        </motion.p>
                        <Row gutter={[8, 8]}>
                            <Col span={size.width > 1024 ? 8 : 24}>
                                <Flex vertical align={"center"} justify={"flex-start"} gap={"middle"}>
                                    <p className={styles.structureText}>Обязательные дисциплины</p>
                                    <motion.div
                                        initial={{scale: 0.8, opacity: 0}}
                                        whileInView={{scale: 1, opacity: 1}}
                                        viewport={{once: true, amount: 0.5}}
                                        transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                        className={styles.boxInfo}>
                                        <ul>
                                            <li>Стратегический менеджмент</li>
                                            <li>Бизнес-исследование</li>
                                            <li>Английский язык (профессиональный)</li>
                                            <li>Корпоративно-правовая ответственность и бизнес-этика</li>
                                            <li>Управление операционными рисками</li>
                                            <li>Управление интеллектуальной собственностью</li>
                                            <li>Международный бизнес</li>
                                            <li>Международная стажировка</li>
                                        </ul>
                                    </motion.div>
                                </Flex>
                            </Col>
                            <Col span={size.width > 1024 ? 16 : 24}>
                                <Flex vertical align={"center"} justify={"center"} gap={"middle"}>
                                    <p className={styles.structureText}>Модули на выбор</p>
                                    <Row gutter={[8, 8]}>
                                        <Col span={size.width >= 481 ? 12 : 24}>
                                            <motion.div
                                                initial={{scale: 0.8, opacity: 0}}
                                                whileInView={{scale: 1, opacity: 1}}
                                                viewport={{once: true, amount: 0.5}}
                                                transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                                className={styles.boxInfo}>
                                                <p>Модуль “Лидерство и финансы”</p>
                                                <ul>
                                                    <li>Управление цифровой экономикой</li>
                                                    <li>Цифровизация в управлении HR</li>
                                                    <li>Управление стоимостью компании</li>
                                                    <li>КФинансовое моделирование и деловой прогноз</li>
                                                </ul>
                                            </motion.div>
                                        </Col>
                                        <Col span={size.width >= 481 ? 12 : 24}>
                                            <motion.div
                                                initial={{scale: 0.8, opacity: 0}}
                                                whileInView={{scale: 1, opacity: 1}}
                                                viewport={{once: true, amount: 0.5}}
                                                transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                                className={styles.boxInfo}>
                                                <ul>
                                                    <li>Управление цифровой экономикой</li>
                                                    <li>Цифровизация в управлении HR</li>
                                                    <li>Управление стоимостью компании</li>
                                                    <li>КФинансовое моделирование и деловой прогноз</li>
                                                </ul>
                                            </motion.div>
                                        </Col>
                                        <Col span={size.width >= 481 ? 12 : 24}>
                                            <motion.div
                                                initial={{scale: 0.8, opacity: 0}}
                                                whileInView={{scale: 1, opacity: 1}}
                                                viewport={{once: true, amount: 0.5}}
                                                transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                                className={styles.boxInfo}>
                                                <ul>
                                                    <li>Управление цифровой экономикой</li>
                                                    <li>Цифровизация в управлении HR</li>
                                                    <li>Управление стоимостью компании</li>
                                                    <li>КФинансовое моделирование и деловой прогноз</li>
                                                </ul>
                                            </motion.div>
                                        </Col>
                                        <Col span={size.width >= 481 ? 12 : 24}>
                                            <motion.div
                                                initial={{scale: 0.8, opacity: 0}}
                                                whileInView={{scale: 1, opacity: 1}}
                                                viewport={{once: true, amount: 0.5}}
                                                transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                                className={styles.boxInfo}>
                                                <ul>
                                                    <li>Управление цифровой экономикой</li>
                                                    <li>Цифровизация в управлении HR</li>
                                                    <li>Управление стоимостью компании</li>
                                                    <li>КФинансовое моделирование и деловой прогноз</li>
                                                </ul>
                                            </motion.div>
                                        </Col>
                                    </Row>
                                </Flex>
                            </Col>
                        </Row>
                    </Flex>
                    <Flex vertical justify={"center"} align={"center"} gap={25}
                          style={{width: "100%", position: "relative"}}>
                        <motion.p
                            initial={{scale: 0}}
                            viewport={{once: true, amount: 0.8}}
                            whileInView={{scale: 1}}
                            transition={{type: 'spring', stiffness: 50, delay: 0.2}}
                            className='title' style={{margin: 0}}>
                            <p className={styles.sectionTitleText}>Наши преподаватели</p>
                        </motion.p>
                        <Flex wrap gap={80} justify={"center"} align={"center"} style={{flexDirection: "row"}}>
                            <div className={styles.teacherCard}>
                                <motion.img
                                    initial={{opacity: 0, scale: 0.8}}
                                    whileInView={{opacity: 1, scale: 1}}
                                    viewport={{once: true, amount: 0.6}}
                                    transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                    src={teacher} alt=""
                                    width={'300px'}/>
                                <motion.h3
                                    initial={{x: -80, opacity: 0}}
                                    whileInView={{x: 0, opacity: 1}}
                                    viewport={{once: true, amount: 0.8}}
                                    transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                >Ярослав Павлов
                                </motion.h3>
                                <motion.h4
                                    initial={{x: 80, opacity: 0}}
                                    whileInView={{x: 0, opacity: 1}}
                                    viewport={{once: true, amount: 0.8}}
                                    transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                >Ректор ИМИСП, преподаватель
                                </motion.h4>
                                <motion.h5
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    viewport={{once: true, amount: 0.6}}
                                    transition={{delay: 0.5, type: 'spring', stiffness: 80}}
                                >Специализируется на стратегии управления инновациями, цифровизации. Собственник
                                    компании IC
                                    Lab. Ранее руководил IT-бизнесом, запустил дистанционное обучение в
                                    бизнес-образовании
                                </motion.h5>
                            </div>
                            <div className={styles.teacherCard}>
                                <motion.img
                                    initial={{opacity: 0, scale: 0.8}}
                                    whileInView={{opacity: 1, scale: 1}}
                                    viewport={{once: true, amount: 0.6}}
                                    transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                    src={teacher} alt=""
                                    width={'300px'}/>
                                <motion.h3
                                    initial={{x: -80, opacity: 0}}
                                    whileInView={{x: 0, opacity: 1}}
                                    viewport={{once: true, amount: 0.8}}
                                    transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                >Ярослав Павлов
                                </motion.h3>
                                <motion.h4
                                    initial={{x: 80, opacity: 0}}
                                    whileInView={{x: 0, opacity: 1}}
                                    viewport={{once: true, amount: 0.8}}
                                    transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                >Ректор ИМИСП, преподаватель
                                </motion.h4>
                                <motion.h5
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    viewport={{once: true, amount: 0.6}}
                                    transition={{delay: 0.5, type: 'spring', stiffness: 80}}
                                >Специализируется на стратегии управления инновациями, цифровизации. Собственник
                                    компании IC
                                    Lab. Ранее руководил IT-бизнесом, запустил дистанционное обучение в
                                    бизнес-образовании
                                </motion.h5>
                            </div>
                            <div className={styles.teacherCard}>
                                <motion.img
                                    initial={{opacity: 0, scale: 0.8}}
                                    whileInView={{opacity: 1, scale: 1}}
                                    viewport={{once: true, amount: 0.6}}
                                    transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                    src={teacher} alt=""
                                    width={'300px'}/>
                                <motion.h3
                                    initial={{x: -80, opacity: 0}}
                                    whileInView={{x: 0, opacity: 1}}
                                    viewport={{once: true, amount: 0.8}}
                                    transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                >Ярослав Павлов
                                </motion.h3>
                                <motion.h4
                                    initial={{x: 80, opacity: 0}}
                                    whileInView={{x: 0, opacity: 1}}
                                    viewport={{once: true, amount: 0.8}}
                                    transition={{delay: 0.2, type: 'spring', stiffness: 80}}
                                >Ректор ИМИСП, преподаватель
                                </motion.h4>
                                <motion.h5
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    viewport={{once: true, amount: 0.6}}
                                    transition={{delay: 0.5, type: 'spring', stiffness: 80}}
                                >Специализируется на стратегии управления инновациями, цифровизации. Собственник
                                    компании IC
                                    Lab. Ранее руководил IT-бизнесом, запустил дистанционное обучение в
                                    бизнес-образовании
                                </motion.h5>
                            </div>
                        </Flex>
                    </Flex>
                    <Flex vertical justify={"center"} align={"center"} gap={25}
                          style={{width: "100%", position: "relative"}}>
                        <motion.h1
                            initial={{scale: 0}}
                            viewport={{once: true, amount: 0.8}}
                            whileInView={{scale: 1}}
                            transition={{type: 'spring', stiffness: 50, delay: 0.2}}
                            style={{margin: 0}}>
                            <p className={styles.sectionTitleText}>Выпускные документы</p>
                        </motion.h1>
                        <Flex gap={size.width <= 1024 ? "large" : "middle"} justify={"space-between"} align={"center"} style={{width: "100%"}} vertical={size.width <= 1024}>
                            <Flex gap={10} vertical align={"center"} className={styles.documentCard}>
                                <motion.div
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    viewport={{once: true, amount: 0.8}}
                                    transition={{delay: 0.2, type: 'spring', stiffness: 80}}>
                                    <Image width={size.width <= 1200 ? 250 : 350} height={size.width <= 1200 ? 300 :400} src={exec1} alt=""/>
                                </motion.div>
                                <motion.p
                                    initial={{y: 40, opacity: 0}}
                                    whileInView={{y: 0, opacity: 1}}
                                    viewport={{once: true, amount: 'some'}}
                                    transition={{delay: 0.5, type: 'spring', stiffness: 80}}
                                >Диплом о присвоении степени Executive MBA Бизнес-школы IMISP, Россия
                                </motion.p>
                            </Flex>
                            <Flex gap={10} vertical align={"center"} className={styles.documentCard}>
                                <motion.div
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    viewport={{once: true, amount: 0.8}}
                                    transition={{delay: 0.2, type: 'spring', stiffness: 80}}>
                                    <Image width={size.width <= 1200 ? 250 : 350} height={size.width <= 1200 ? 300 :400} src={exec2} alt=""/>
                                </motion.div>
                                <motion.p
                                    className={styles.responsiveImage}
                                    initial={{y: 40, opacity: 0}}
                                    whileInView={{y: 0, opacity: 1}}
                                    viewport={{once: true, amount: 'some'}}
                                    transition={{delay: 0.5, type: 'spring', stiffness: 80}}
                                >Диплом о присвоении степени Executive MBA Бизнес-школы IMISP, Россия
                                </motion.p>
                            </Flex>
                            <Flex gap={10} vertical align={"center"} className={styles.documentCard}>
                                <motion.div
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    viewport={{once: true, amount: 0.8}}
                                    transition={{delay: 0.2, type: 'spring', stiffness: 80}}>
                                    <Image width={size.width <= 1200 ? 250 : 350} height={size.width <= 1200 ? 300 :400} src={exec3} alt="" wrapperStyle={{backgroundPosition: "center", objectFit: "cover"}}/>
                                </motion.div>
                                <motion.p
                                    initial={{y: 40, opacity: 0}}
                                    whileInView={{y: 0, opacity: 1}}
                                    viewport={{once: true, amount: 'some'}}
                                    transition={{delay: 0.5, type: 'spring', stiffness: 80}}
                                >Диплом о присвоении степени Executive MBA Бизнес-школы IMISP, Россия
                                </motion.p>
                            </Flex>
                        </Flex>
                    </Flex>
                    {/*<StatsSection/>*/}
                    <PartnersSection/>
                </Flex>
            </Flex>
            <Footer/>
        </div>
    )
        ;
};

export default MbaPage;