import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Flex, Form, Input, message, Select} from "antd";
import {LocalName} from "../../../../../utils/LocalName";
import classes from "../../Courses/CreateCourse/CreateCourse.module.css";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import FormItem from "antd/es/form/FormItem";
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";
import {clrs} from "../../../../../constants/colors";
import UserService from "../../../../../services/UserService";
import CategoryService from "../../../../../services/CategoryService";
import GroupService from "../../../../../services/GroupService";

const CreationGroups = () => {
    const dispatch = useDispatch();

    const [categories, setCategories] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [students, setStudents] = useState([]);
    const [isLoadingCreate, setIsLoadingCreate] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingTeachers, setIsLoadingTeachers] = useState(false);
    const [isLoadingStudents, setIsLoadingStudents] = useState(false);
    const [isLoadingCategories, setIsLoadingCategories] = useState(false);

    const [form] = Form.useForm();
    const category = Form.useWatch('category', form);
    const name = Form.useWatch('name', form);
    const number = Form.useWatch('number', form);
    const teacher = Form.useWatch('teacher', form);
    const selectedStudents = Form.useWatch('students', form);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoadingData(true);
        try {
            const [teachersResponse, studentsResponse, categoriesResponse] = await Promise.allSettled([
                UserService.getTeachers(),
                UserService.getStudents(),
                CategoryService.getAllCategories(),
            ]);

            if (teachersResponse.status === 'fulfilled') {
                setTeachers(teachersResponse.value.data.map(teacher => {
                    return {
                        value: teacher.id,
                        label: (teacher.profile.firstName + " " + teacher.profile.lastName)
                    }
                }));
            } else {
                message.error(<IntlMessage id="teachersFetchError"/>, 5);
            }

            if (studentsResponse.status === 'fulfilled') {
                setStudents(studentsResponse.value.data.map(student => {
                    return {
                        value: student.id,
                        label: (student.profile.firstName + " " + student.profile.lastName)
                    }
                }));
            } else {
                message.error(<IntlMessage id="studentsFetchError"/>, 5);
            }

            if (categoriesResponse.status === 'fulfilled') {
                setCategories(categoriesResponse.value.data.map((category) => {
                    return {value: category.id, label: LocalName.getName(category)};
                }));
            } else {
                message.error(<IntlMessage id="categoriesFetchError"/>, 5);
            }
        } catch (error) {
            message.error(<IntlMessage id="error"/>, 5);
        } finally {
            setIsLoadingData(false);
        }
    };

    function handleCreate() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setIsLoadingCreate(true);
                await GroupService.saveGroup(name, number, category, teacher, selectedStudents)
                    .then(() => {
                        message.destroy();
                        message.success(<IntlMessage id={'creation'}/>, 5);
                        form.resetFields();
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                        setIsLoadingCreate(false);
                    })
                    .finally(() => {
                        setIsLoadingCreate(false);
                    })
            })
            .catch(() => {
                console.log("Validation error!")
            })
            .finally(() => {
                setIsLoadingCreate(false);
            })
    }

    return (
        <Form form={form} className={classes.form}>
            <BlockLoading isLoading={isLoadingCreate}/>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'name2'}/>} labelCol={{span: 24}} className={classes.formInputBlock}
                      name="name">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "name2"})}/>
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'number'}/>} labelCol={{span: 24}} className={classes.formInputBlock}
                      name="number">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "number"})}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInputBlock} name="category"
                      label={<IntlMessage id={'categoryCourse'}/>} labelCol={{span: 24}}
            >
                <Select
                    options={categories}
                    placeholder={IntlMessageText.getText({id: "categoryCourse"})}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInputBlock} name={"teacher"}
                      label={<IntlMessage id={'ordersPage.teacher'}/>} labelCol={{span: 24}}
            >
                <Select options={teachers}
                        placeholder={<IntlMessage id={'ordersPage.teacher'}/>}
                />
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInputBlock} name={"students"}
                      label={<IntlMessage id={'ordersPage.students'}/>} labelCol={{span: 24}}
            >
                <Select mode="multiple" allowClear options={students}
                        placeholder={<IntlMessage id={'ordersPage.students'}/>}/>
            </FormItem>
            <FormItem className={classes.formInputBlock}>
                <Flex align={"flex-start"} justify={"flex-start"} gap={20} style={{width: "100%"}}>
                    <GreyButton onClick={handleCreate} backgroundColor={clrs.blackerBlue}
                                fontColor={clrs.white}><IntlMessage
                        id={'create'}/></GreyButton>
                </Flex>
            </FormItem>
        </Form>
    );
};

export default CreationGroups;