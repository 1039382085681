import React from 'react';
import BannerForm from "./BannerForm";
import classes from './Banner.module.css';
import {useWindowSize} from "@uidotdev/usehooks";
import {Fade} from "react-awesome-reveal";
import {Flex} from "antd";
import {motion} from "framer-motion";
import foundationBanner from "../../../../images/foundationBanner.png"
import coursesBanner from "../../../../images/coursesBanner.png"
import digitalmbaBanner from "../../../../images/digitalmbaBanner.png"
import mbaBanner from "../../../../images/mbaBanner.png"

const NewBanner = () => {
    const size = useWindowSize();

    return (
            <Flex style={{width: "100%", position: "relative"}} gap={20}
                  vertical={size.width <= 1300}>
                <Fade triggerOnce={true} cascade damping={0.15} style={{width: size.width > 1300 ? "calc(100% - 450px - 20px)" : "100%", position: "relative"}}>
                    <div
                        style={{
                            width: '100%',
                            borderRadius: '25px',
                            overflow: 'hidden',
                            '--swiper-pagination-color': 'white',
                            cursor: 'grab'
                        }}
                    >
                        <swiper-container
                            style={{height: '100%'}}
                            loop={true}
                            slides-per-view={1}
                            pagination={true}
                            pagination-clickable={true}
                            autoplay={true}
                            autoplay-pause-on-mouse-enter={true}
                            space-between={30}
                        >
                            <swiper-slide><img src={foundationBanner} alt=""
                                               style={{width: '100%', height: '100%', objectFit: "fill"}}/>
                            </swiper-slide>
                            <swiper-slide><img src={mbaBanner} alt=""
                                               style={{width: '100%', height: '100%', objectFit: "fill"}}/>
                            </swiper-slide>
                            <swiper-slide><img src={coursesBanner} alt=""
                                               style={{width: '100%', height: '100%', objectFit: "fill"}}/>
                            </swiper-slide>
                            <swiper-slide><img src={digitalmbaBanner} alt=""
                                               style={{width: '100%', height: '100%', objectFit: "fill"}}/>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                </Fade>

                {
                    size.width > 1024 &&
                    <Fade triggerOnce={true} cascade className={classes.fadeBlock}>
                        <BannerForm/>
                    </Fade>
                }

            </Flex>
    );
};

export default NewBanner;