import {API_BASE_URL} from "../constants/api";
import axios from "axios";

const CalculationService = {};

CalculationService.getCoursesPrice = async function (totalPrice, discount, numberOfTransh) {

    return axios({
        method: "get",
        url: API_BASE_URL + `/api/calculation/totalPrice`,
        params: {
            totalPrice: totalPrice,
            discount: discount,
            numberOfTransh: numberOfTransh,
        },
        headers: {
            "Content-Type": "application/json"
        }
    });
}

export default CalculationService;