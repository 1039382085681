import React from 'react';
import {Typography} from "antd";
import PropTypes from "prop-types";

const MyText = ({ uppercase, medium, strong, stronger, italic, size, children, ...rest}) => {
    const style = {
        ...(uppercase && { textTransform: "uppercase" }),
        ...(medium && { fontWeight: "400" }),
        ...(strong && { fontWeight: "700" }),
        ...(stronger && { fontWeight: "800" }),
        ...(italic && { fontStyle: "italic" }),
        ...(size === "small" && { fontSize: "0.85rem" }),
        ...(size === "default" && { fontSize: "1rem" }),
        ...(size === "bigger" && { fontSize: "1.25rem" }),
        ...(size === "large" && { fontSize: "1.5rem" }),
        ...(size === "larger" && { fontSize: "2rem" }),
        ...(size === "x-large" && { fontSize: "2.5rem" }),
        ...(!size && { fontSize: "1rem" }),
        ...rest.style
    };

    return (
        <p {...rest} style={style}>
            {children}
        </p>
    );
};
MyText.propTypes = {
    ...Typography.Text.propTypes,
    uppercase: PropTypes.bool,
    medium: PropTypes.bool,
    strong: PropTypes.bool,
    stronger: PropTypes.bool,
    italic: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'default', 'bigger', 'large', 'larger', 'x-large']),
    children: PropTypes.node,
};

export default MyText;