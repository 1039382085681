import React, {useCallback, useEffect, useRef, useState} from 'react';
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import TableWithPagination from "../../../../../components/TableWithPagination/TableWithPagination";
import {
    message,
    Tag,
    Modal,
    Drawer,
    Space,
    Button as AntdButton,
    Divider,
    Form,
    Radio,
    Flex,
    Table,
    Input,
    Button, Popconfirm
} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getAllApplicationsPageable} from "../../../../../store/slices/ApplicationsListSlice";
import {changeCurrentPage} from "../../../../../store/slices/tableController/ApplicationsListTableController";
import {saveForm} from "../../../../../store/slices/FormSaveSlice";
import {ExclamationCircleFilled, PlusOutlined} from "@ant-design/icons";
import {rejectApplicationById} from "../../../../../store/slices/ApplicationSlice";
import {clrs} from "../../../../../constants/colors";
import classes from "../application.module.css";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {LocalName} from "../../../../../utils/LocalName";
import {API_BASE_URL, CLIENT_BASE_URL} from "../../../../../constants/api";
import {getAllCategories} from "../../../../../store/slices/CategoriesListSlice";
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";
import BlackerBlueButton from "../../../../../components/UI/Buttons/BlackerBlueButton";
import FormService from "../../../../../services/FormService";
import ApplicationService from "../../../../../services/ApplicationService";
import RedButton from "../../../../../components/UI/Buttons/RedButton";
import GreenButton from "../../../../../components/UI/Buttons/GreenButton";
import SignatureService from "../../../../../services/SignatureService";
import {signContract} from "../../../../../utils/signContract";
import {getFormById} from "../../../../../store/slices/FormSlice";


const { confirm } = Modal;
const ApplicationList = () => {
    const {applications, hasMore, isLoading} = useSelector(state => state.applicationsList);
    const application = useSelector(state => state.application);

    const formSave = useSelector(state => state.formSave);

    const {currentPage, pageSize} = useSelector(state => state.applicationsListTableController);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const hasSelected = selectedRowKeys.length > 0;

    const [loadingFormMultipleSave, setLoadingFormMultipleSave] = useState(false);
    const [isLoadingSignApplications, setIsLoadingSignApplications] = useState(false);

    const [openApplicationCommentModal, setOpenApplicationCommentModal] = useState(false);
    const [applicationCommentForm] = Form.useForm();
    const applicationComment = Form.useWatch('applicationComment', applicationCommentForm);
    const applicationCommentInput = useRef(null)

    const [openApplicationNotifyModal, setOpenApplicationNotifyModal] = useState(false);
    const [applicationNotifyForm] = Form.useForm();
    const emailSubject = Form.useWatch('emailSubject', applicationNotifyForm);
    const emailMessage = Form.useWatch('emailMessage', applicationNotifyForm);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [filterForm] = Form.useForm();
    const status = Form.useWatch('status', filterForm);
    const categoryId = Form.useWatch('categoryId', filterForm);
    const fullName = Form.useWatch('fullName', filterForm);

    useEffect(() => {
        dispatch(getAllApplicationsPageable({page: 1, limit: 100, applicationStatus: status, categoryId, fullName }))
    }, [navigate]);

    useEffect(() => {
        if (openApplicationCommentModal) {
            applicationCommentInput.current.focus();
        }
    }, [openApplicationCommentModal]);

    const categoriesList = useSelector(state => state.categoriesList);

    useEffect(() => {
        dispatch(getAllCategories());
    }, []);

    const fetchData = async (params) => {
        return dispatch(getAllApplicationsPageable({...params, applicationStatus: status, categoryId, fullName}));
    }

    const saveCurrentPageSettings = (page, pageSize) => {
        dispatch(changeCurrentPage({page: page, pageSize: pageSize}));
    };

    async function handleSaveForm(applicationId) {
        await dispatch(saveForm({applicationId: applicationId}));
        await dispatch(getAllApplicationsPageable({page: currentPage, limit: pageSize, applicationStatus: status, categoryId, fullName }));
    }

    async function handleSaveFormMultiple() {
        setLoadingFormMultipleSave(true);
        await FormService.saveFormMultiple(selectedRowKeys)
            .then((r) => {
                message.success(<IntlMessage id={'success'}/>,5);
                dispatch(getAllApplicationsPageable({page: currentPage, limit: pageSize, applicationStatus: status, categoryId, fullName }));
                setLoadingFormMultipleSave(false);
                setSelectedRowKeys([]);
            })
            .catch(() => {
                message.error(<IntlMessage id={'error'}/>,5);
                setLoadingFormMultipleSave(false);
            })
    }

    async function handleDeleteApplicationMultiple() {
        setLoadingFormMultipleSave(true);
        await ApplicationService.deleteApplicationsById(selectedRowKeys)
            .then((r) => {
                message.success(<IntlMessage id={'success'}/>,5);
                dispatch(getAllApplicationsPageable({page: currentPage, limit: pageSize, applicationStatus: status, categoryId, fullName }));
                setLoadingFormMultipleSave(false);
                setSelectedRowKeys([]);
            })
            .catch(() => {
                message.error(<IntlMessage id={'error'}/>,5);
                setLoadingFormMultipleSave(false);
            })
    }

    async function handleCommentApplicationMultiple() {
        setLoadingFormMultipleSave(true);
        setOpenApplicationCommentModal(false);
        await ApplicationService.commentApplicationsById(selectedRowKeys, applicationComment)
            .then((r) => {
                message.success(<IntlMessage id={'success'}/>,5);
                dispatch(getAllApplicationsPageable({page: currentPage, limit: pageSize, applicationStatus: status, categoryId, fullName }));
                setLoadingFormMultipleSave(false);
                setSelectedRowKeys([]);
                applicationCommentForm.resetFields();
            })
            .catch(() => {
                message.error(<IntlMessage id={'error'}/>,5);
                setLoadingFormMultipleSave(false);
            })
    }

    async function handleNotifyApplicationMultiple() {
        setLoadingFormMultipleSave(true);
        setOpenApplicationNotifyModal(false);
        await ApplicationService.notifyApplicationsById(selectedRowKeys, emailSubject, emailMessage)
            .then((r) => {
                message.success(<IntlMessage id={'success'}/>,5);
                dispatch(getAllApplicationsPageable({page: currentPage, limit: pageSize, applicationStatus: status, categoryId, fullName }));
                setLoadingFormMultipleSave(false);
                setSelectedRowKeys([]);
                applicationNotifyForm.resetFields();
            })
            .catch(() => {
                message.error(<IntlMessage id={'error'}/>,5);
                setLoadingFormMultipleSave(false);
            })
    }

    async function handleSignContract(signatureType) {
        message.loading(<IntlMessage id={'changing'}/>, 0);
        await signContract(signatureType, selectedRowKeys, null, null)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
            })
            .catch((reason) => {
                message.destroy();
                if (reason instanceof Error) {
                    const errorMessage = reason.message;
                    if (errorMessage === "NCAOpenError") {
                        message.error(<IntlMessage id="NCAOpenError" />, 5);
                    } else {
                        message.error(<IntlMessage id={'error'} />, 5);
                    }
                } else {
                    message.error(<IntlMessage id={'error'} />, 5);
                }
            }).finally(() => {
                dispatch(getAllApplicationsPageable({page: currentPage, limit: pageSize, applicationStatus: status, categoryId, fullName }));
                setSelectedRowKeys([]);
                applicationNotifyForm.resetFields();
            });
    }


    const showRejectApplicationModal = (id, application) => {
        confirm({
            title: <IntlMessage id={'doYouWantReject'}/>,
            icon: <ExclamationCircleFilled />,
            okText: <IntlMessage id={'yes'}/>,
            maskClosable: true,
            cancelText: <IntlMessage id={'no'}/>,
            content: <Flex style={{width: "100%"}} align={"flex-start"} justify={"center"} gap={5} vertical>
                <p><IntlMessage id={'name'}/> : {application.name}</p>
                <p><IntlMessage id={'email'}/> : {application.email}</p>
                <p><IntlMessage id={'telNumber'}/> : {application.telNumber}</p>
            </Flex>,
            async onOk() {
                await dispatch(rejectApplicationById({id: id}));
                await dispatch(getAllApplicationsPageable({page: currentPage, limit: pageSize, applicationStatus: status, categoryId, fullName }));
            },
            onCancel() {},
        });
    };

    const columns = [
        {
            title: <IntlMessage id={'categoryCourse'}/>,
            render: (_, record) => (
                <p>{LocalName.getName(record.category)}</p>
            ),
            width: '10%',
        },
        {
            title: <IntlMessage id={'date'}/>,
            render: (_, record) => (
                <p>{new Date(record.updatedAt).toLocaleDateString()}</p>
            ),
            width: '7%',
        },
        {
            title: <IntlMessage id={'name'}/>,
            render: (_, record) => (
                <p>{record.name}</p>
            ),
            // width: '100%',
        },

        {
            title: <IntlMessage id={'telNumber'}/>,
            render: (_, record) => (
                <p>{record.telNumber}</p>
            ),
            width: '10%',
        },
        {
            title: <IntlMessage id={'status'}/>,
            render: (_, record) => (
                <Flex style={{width: "100%"}} align={"center"} justify={"center"} gap={20}>
                    {record.status === 'NEW' &&
                        <Tag color={clrs.green}>{record.status}</Tag>
                    }
                    {record.status === 'IN_PROGRESS' &&
                        <Tag color={clrs.blue}>{record.status}</Tag>
                    }
                    {record.status === 'FILLED' &&
                        <Tag color={clrs.grey}>{record.status}</Tag>
                    }
                    {record.status === 'REJECTED' &&
                        <Tag color={clrs.whiterRed}>{record.status}</Tag>
                    }
                    {record.status === 'COMPLETED' &&
                        <Tag color={clrs.blackerBlue}>{record.status}</Tag>
                    }
                    {record.status === 'CONTRACT' &&
                        <Tag color={clrs.whiterGreen}>{record.status}</Tag>
                    }
                </Flex>
            ),
            width: '8%',
        },
        {
            title: 'B1',
            render: (_, record) => (
                <>
                    {record.form?.idCardFiles.length > 0 && <PlusOutlined />}
                    {record.form?.idCardFiles.length > 1 && record.form?.idCardFiles.length}
                </>
            ),
            width: '4%',
        },
        {
            title: 'B2',
            render: (_, record) => (
                <>
                    {record.form?.otherFiles.length > 0 && <PlusOutlined />}
                    {record.form?.otherFiles.length > 1 && record.form?.otherFiles.length}
                </>
            ),
            width: '4%',
        },
        {
            title: 'SS',
            render: (_, record) => (
                <>
                    {!!record.form?.studentSignature && <PlusOutlined />}
                </>
            ),
            width: '4%',
        },
        {
            title: 'PS',
            render: (_, record) => (
                <>
                    {!!record.form?.parentSignature && <PlusOutlined />}
                </>
            ),
            width: '4%',
        },
        {
            title: <IntlMessage id={'applicationsPage.comment'}/>,
            render: (_, record) => (
                <p>{record.comment}</p>
            ),
            width: '20%',
        },
        {
            title: <IntlMessage id={'form'}/>,
            render: (_, record) => (
                <>
                    {
                        record.category.formType !== null &&
                        <Flex style={{width: "100%"}} align={"center"} justify={"center"} wrap={"wrap"} gap={20}>
                            {
                                record.status !== 'CONTRACT' ?
                                <>
                                    {record.status === 'NEW' &&
                                        <p onClick={() => {handleSaveForm(record.id)}} className={classes.copyLinkBtn}><IntlMessage id={'createForm'}/></p>
                                    }
                                    {(record.status !== 'NEW' && record.status !== 'REJECTED')
                                        &&
                                        <>
                                            <CopyToClipboard text={CLIENT_BASE_URL + "/form/" + record.form.id} onCopy={() => {
                                                message.success(<IntlMessage id={'linkCopied'}/>, 5);
                                            }} ><p className={classes.copyLinkBtn}><IntlMessage id={'copyLinkShort'}/></p></CopyToClipboard>
                                            <a href={CLIENT_BASE_URL + "/form/" + record.form.id} target={"_blank"} className={classes.copyLinkBtn}>Открыть</a>
                                        </>
                                    }
                                    {record.status !== 'REJECTED' &&
                                        <p onClick={() => {showRejectApplicationModal(record.id, record)}} className={classes.rejectBtn}><IntlMessage id={'reject'}/></p>
                                    }
                                </>
                                    :
                                    <>
                                        <a href={CLIENT_BASE_URL + "/document/" + record.form.documentId} target={"_blank"}
                                           className={classes.copyLinkBtn}>Открыть</a>
                                    </>
                            }
                        </Flex>
                    }

                </>
            ),
            width: '15%',
        },
    ];

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20} vertical>
            <BlockLoading isLoading={formSave.isLoading || application.isLoading || loadingFormMultipleSave}/>
            <Flex style={{width: "100%"}} align={"center"} justify={"center"} gap={20} wrap={"wrap"}>
                <GreyButton
                    onClick={() => {filterForm.resetFields();}}
                    backgroundColor={clrs.blackerBlue} fontColor={clrs.white}><IntlMessage id={'clear'}/></GreyButton>
                <GreyButton onClick={() => {
                    dispatch(getAllApplicationsPageable({page: currentPage, limit: pageSize, applicationStatus: status, categoryId, fullName }))
                }} backgroundColor={clrs.whiter} fontColor={clrs.black}><IntlMessage id={'update'}/></GreyButton>
                {
                    hasSelected &&
                    <BlackerBlueButton onClick={handleSaveFormMultiple}>
                        <IntlMessage id={'createForm'}/>
                    </BlackerBlueButton>
                }
                {
                    hasSelected &&
                    <BlackerBlueButton onClick={() => {setOpenApplicationCommentModal(true)}}>
                        <IntlMessage id={'applicationsPage.comment'}/>
                    </BlackerBlueButton>
                }
                {
                    hasSelected &&
                    <GreenButton onClick={() => {setOpenApplicationNotifyModal(true)}}>
                        <IntlMessage id={'applicationsPage.notification'}/>
                    </GreenButton>

                }
                {
                    hasSelected &&
                    <Popconfirm
                        title={<IntlMessage id={'delete'}/>}
                        description={<IntlMessage id={'formPage.youSureToDelete'}/>}
                        onConfirm={handleDeleteApplicationMultiple}
                        // onCancel={cancel}
                        okText={<IntlMessage id={'yes'}/>}
                        cancelText={<IntlMessage id={'no'}/>}
                    >
                        <RedButton>
                            <IntlMessage id={'delete'}/>
                        </RedButton>
                    </Popconfirm>
                }
                {
                    hasSelected &&
                    <GreenButton onClick={() => handleSignContract("director", selectedRowKeys, null, null)}>
                        <IntlMessage id={'sign'}/>
                    </GreenButton>

                }

            </Flex>
            <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20} vertical>
                <Form onKeyUp={(e) => {if (e.key === "Enter") {
                    dispatch(getAllApplicationsPageable({page: currentPage, limit: pageSize, applicationStatus: status, categoryId, fullName }))
                }}} form={filterForm} className={classes.form}>
                    <Flex style={{width: "100%"}} vertical align={"flex-start"} justify={"flex-start"}>
                        <Divider className={classes.divider} orientation="left">
                            <IntlMessage id={'fullName'}/>/<IntlMessage id={'telNumber'}/>
                        </Divider>
                        <Form.Item name="fullName" className={classes.formInputBlock}>
                            <Input className={classes.formInput} placeholder={"Азамат/8707"}/>
                        </Form.Item>
                    </Flex>
                    <Flex style={{width: "100%", position: "relative"}} align={"flex-start"} justify={"flex-start"}>
                        <Flex style={{width: "50%"}} vertical align={"flex-start"} justify={"flex-start"}>
                            <Divider className={classes.divider} orientation="left"><IntlMessage id={'category'}/></Divider>
                            <Form.Item name="categoryId" >
                                <Radio.Group >
                                    <Space wrap={"wrap"}>
                                        {
                                            categoriesList.categories.map((category, i) => {
                                                return (
                                                    <Radio value={category.id} key={category.id}>{LocalName.getName(category)}</Radio>
                                                )
                                            })
                                        }
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Flex>
                        <Flex style={{width: "50%"}} vertical align={"flex-start"} justify={"flex-start"}>
                            <Divider className={classes.divider} orientation="left"><IntlMessage id={'status'}/></Divider>
                            <Form.Item name="status" >
                                <Radio.Group >
                                    <Space wrap={"wrap"}>
                                        <Radio value={"NEW"} ><Tag color={clrs.green}>NEW</Tag></Radio>
                                        <Radio value={"IN_PROGRESS"} ><Tag color={clrs.blue}>IN_PROGRESS</Tag></Radio>
                                        <Radio value={"FILLED"} ><Tag color={clrs.grey}>FILLED</Tag></Radio>
                                        <Radio value={"REJECTED"} ><Tag color={clrs.whiterRed}>REJECTED</Tag></Radio>
                                        <Radio value={"COMPLETED"} ><Tag color={clrs.blackerBlue}>COMPLETED</Tag></Radio>
                                        <Radio value={"CONTRACT"} ><Tag color={clrs.whiterGreen}>CONTRACT</Tag></Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Flex>
                    </Flex>



                </Form>
            </Flex>


            <MyTable
                isLoading={isLoading}
                dataSource={applications}
                columns={columns}
                fetchData={fetchData}
                saveCurrentPage={saveCurrentPageSettings}
                initialPage={currentPage}
                initialPageSize={pageSize}
                hasMore={hasMore}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
            />

            <Modal
                width={500}
                maskClosable={true}
                title={<IntlMessage id={'applicationsPage.comment'}/>}
                open={openApplicationCommentModal}
                onOk={handleCommentApplicationMultiple}
                onCancel={() => {setOpenApplicationCommentModal(false)}}
                okText={<IntlMessage id={'save'}/>}
                cancelText={<IntlMessage id={'close'}/>}
            >
                <Form form={applicationCommentForm} className={classes.form}>
                    <Form.Item name="applicationComment" className={classes.formInputBlock}>
                        <Input.TextArea ref={applicationCommentInput} className={classes.formInput} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                width={800}
                maskClosable={true}
                title={<IntlMessage id={'applicationsPage.notification'}/>}
                open={openApplicationNotifyModal}
                onOk={handleNotifyApplicationMultiple}
                onCancel={() => {setOpenApplicationNotifyModal(false)}}
                okText={<IntlMessage id={'send'}/>}
                cancelText={<IntlMessage id={'close'}/>}
            >
                <Form
                    form={applicationNotifyForm}
                    className={classes.form}
                    initialValues={{
                        emailSubject: "Курсы английского \"General English\"",
                        emailMessage: "Добрый день! Продолжите пожалуйста регистрацию на курс английского языка \"General English\". Пройдите по ссылке ({form_link})"
                    }}
                >
                    <Form.Item
                        label={<IntlMessage id={'applicationsPage.notification.subject'}/>}
                        labelCol={{ span: 24 }}
                        name="emailSubject" className={classes.formInputBlock}>
                        <Input className={classes.formInput} />
                    </Form.Item>
                    <Form.Item
                        label={<IntlMessage id={'applicationsPage.notification.message'}/>}
                        labelCol={{ span: 24 }}
                        name="emailMessage" className={classes.formInputBlock}>
                        <Input.TextArea
                            style={{ height: 120}}
                            className={classes.formInput} />
                    </Form.Item>
                </Form>
            </Modal>
        </Flex>
    );
};

const MyTable = ({
    isLoading,
    dataSource,
    columns,
    fetchData,
    saveCurrentPage,
    initialPage = 1,
    initialPageSize = 100,
    hasMore = true,
    expandable,
    selectedRowKeys,
    setSelectedRowKeys
}) => {
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [currentPage, setCurrentPage] = useState(initialPage);
    const [currentPageSize, setCurrentPageSize] = useState(initialPageSize);
    const [hasNextPage, setHasNextPage] = useState(hasMore);

    useEffect(() => {
        setHasNextPage(hasMore);
    }, [hasMore]);

    useEffect(() => {
        setCurrentPage(initialPage);
        setCurrentPageSize(initialPageSize);
    }, [initialPage, initialPageSize]);

    // Handle pagination change by updating the current page and page size.
    // Then, fetch data using the provided fetchData function.
    const handlePaginationChange = useCallback(
        (page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
            fetchData({ page, limit: pageSize }).then((result) => {
                const hasMore = result.payload.hasMore;
                setHasNextPage(hasMore);
            });
            saveCurrentPage(page, pageSize);
        },
        [fetchData],
    );

    return (
        <Table
            rowSelection={rowSelection}
            bordered
            columns={columns}
            dataSource={dataSource}
            pagination={{
                current: currentPage,
                total: hasNextPage
                    ? currentPage * currentPageSize + 1
                    : currentPage * currentPageSize,
                defaultPageSize: initialPageSize,
                showSizeChanger: true,
                pageSize: currentPageSize,
                pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
                onChange: handlePaginationChange,
                onShowSizeChange: handlePaginationChange,
            }}
            rowKey={record => record.id}
            loading={isLoading}
            style={{
                width: "100%",
            }}
            scroll={{ x: 800 }}
        />
    )
}

export default ApplicationList;