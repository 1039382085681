import React, {useState} from 'react';
import styles from './MainSection.module.css';
import {Col, Flex, Row} from "antd";
import MyText from "../../../../components/UI/Text/MyText";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import people from "../../../../images/people-yellow.png"
import person from "../../../../images/person.svg"
import hat from "../../../../images/hat.svg"
import computer from "../../../../images/computer.svg"
import one from "../../../../images/1.png"
import onev2 from "../../../../images/1-1.png"
import two from "../../../../images/2.png"
import twov2 from "../../../../images/2-2.png"
import three from "../../../../images/3.png"
import threev2 from "../../../../images/3-3.png"
import four from "../../../../images/4.png"
import fourv2 from "../../../../images/4-4.png"
import matrix from "../../../../images/matrix.svg"
import bracket from "../../../../images/bracket.png"
import data from "../../../../images/data.svg"
import html from "../../../../images/html.svg"
import java from "../../../../images/java.svg"
import python from "../../../../images/python.svg"
import cert from "../../../../images/cert.png"
import {useWindowSize} from "@uidotdev/usehooks";
import {clrs} from "../../../../constants/colors";
import {Fade} from "react-awesome-reveal";

const MainSection = () => {
    const [isInView, setIsInView] = useState(false);
    const [isInViewProgs, setIsInViewProgs] = useState(false);
    const size = useWindowSize();
    return (
        <Flex vertical align={"center"} justify={"center"} className={styles.content} gap={40}>
            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                <MyText stronger size={"larger"} style={{textAlign: "center"}} uppercase><IntlMessage
                    id={"ourAdvantages"}/></MyText>
            </Fade>
            <Flex style={{height: size.width > 800 && 170}} gap={20} align={"center"} justify={"space-between"}
                  vertical={size.width <= 768}>
                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                    <div className={styles.card}>
                        <img src={computer} alt="computer"/>
                        <MyText strong size={"default"} center><IntlMessage id={'offOnLearn'}/></MyText>
                    </div>
                    <div className={styles.card}>
                        <img src={hat} alt="hat"/>
                        <MyText strong size={"default"} center><IntlMessage id={'skilledTeachers'}/></MyText>
                    </div>
                    <div className={styles.card}>
                        <img src={person} alt="person"/>
                        <MyText strong size={"default"} center><IntlMessage id={'authorsProgram'}/></MyText>
                    </div>
                    {
                        size.width > 1024 &&
                        <img
                            style={{height: '100%', objectFit: "fill"}}
                            src={people}
                            alt=""
                        />
                    }
                </Fade>


            </Flex>
            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                <MyText stronger size={"larger"} uppercase style={{textAlign: "center"}}><IntlMessage
                    id={"whyCool"}/></MyText>
            </Fade>

            <Flex style={{width: '100%', position: "relative"}} align={"center"} justify={"center"}>
                <div style={{
                    position: "absolute",
                    backgroundColor: "#E9E9E9",
                    width: "calc(100vw - 5px)",
                    height: "100%"
                }}></div>
                <div className={styles.gray_box}>
                    <div>
                        <Flex style={{width: '100%'}} gap={20} justify={"center"} align={"center"}
                              vertical={size.width <= 700}>
                            <Flex gap={20} justify={"space-between"} vertical={size.width <= 1300}>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"right"} duration={500}>
                                    <div>
                                        <swiper-container
                                            style={{width: 300, margin: 0}}
                                            loop={true}
                                            slides-per-view={1}
                                            autoplay={true}
                                            cross-fade={true}
                                            effect={'fade'}
                                        >
                                            <h3 className={styles.personText}><IntlMessage id={"highStability"}/></h3>
                                            <swiper-slide>
                                                <img className={styles.personImg} src={one} alt=""/>
                                            </swiper-slide>

                                            <swiper-slide>
                                                <img className={styles.personImg} src={onev2} alt=""/>
                                            </swiper-slide>
                                        </swiper-container>
                                    </div>

                                    <div>
                                        <swiper-container
                                            style={{width: 300, margin: 0}}
                                            loop={true}
                                            slides-per-view={1}
                                            autoplay={true}
                                            cross-fade={true}
                                            effect={'fade'}
                                        >
                                            <h3 className={styles.personText}><IntlMessage id={"conDevTrain"}/></h3>
                                            <swiper-slide>
                                                <img className={styles.personImg} src={two} alt=""/>
                                            </swiper-slide>

                                            <swiper-slide>
                                                <img className={styles.personImg} src={twov2} alt=""/>
                                            </swiper-slide>
                                        </swiper-container>
                                    </div>
                                </Fade>

                            </Flex>
                            <Flex gap={20} justify={"space-between"} vertical={size.width <= 1300}>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"right"} delay={250}
                                      duration={500}>
                                    <div>
                                        <swiper-container
                                            style={{width: 300, margin: 0}}
                                            loop={true}
                                            slides-per-view={1}
                                            autoplay={true}
                                            cross-fade={true}
                                            effect={'fade'}
                                        >
                                            <h3 className={styles.personText}><IntlMessage id={"abilWorkAnywhere"}/>
                                            </h3>
                                            <swiper-slide>
                                                <img className={styles.personImg} src={three} alt=""/>
                                            </swiper-slide>

                                            <swiper-slide>
                                                <img className={styles.personImg} src={threev2} alt=""/>
                                            </swiper-slide>
                                        </swiper-container>
                                    </div>

                                    <div>
                                        <swiper-container
                                            style={{width: 300, margin: 0}}
                                            loop={true}
                                            slides-per-view={1}
                                            autoplay={true}
                                            cross-fade={true}
                                            effect={'fade'}
                                        >
                                            <h3 className={styles.personText}><IntlMessage id={"crtAndInno"}/></h3>
                                            <swiper-slide>
                                                <img className={styles.personImg} src={four} alt=""/>
                                            </swiper-slide>
                                            <swiper-slide>
                                                <img className={styles.personImg} src={fourv2} alt=""/>
                                            </swiper-slide>
                                        </swiper-container>
                                    </div>
                                </Fade>
                            </Flex>
                        </Flex>
                    </div>
                </div>
            </Flex>

            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                <MyText stronger size={"larger"} style={{textAlign: "center"}} uppercase><IntlMessage
                    id={"popularPrograms"}/></MyText>
            </Fade>
            <div style={{width: '100%'}}>
                <Row gutter={[16, 16]} justify={"center"}>
                    <Col xl={8} md={12} sm={24} xs={24}>
                        <Fade triggerOnce={true} cascade damping={0.15}>
                            <div className={styles.cardProgs} style={{background: '#110BFF', color: 'white'}}>
                                <img src={matrix} alt=""
                                     style={{position: 'absolute', left: -300, width: 560, height: 'auto'}}/>
                                <h2 style={{width: '45%', marginLeft: 'auto', marginRight: 40}}>
                                    <IntlMessage id={"introToAI"}/>
                                </h2>
                            </div>
                        </Fade>

                    </Col>
                    <Col xl={8} md={12} sm={24} xs={24}>
                        <Fade triggerOnce={true} cascade damping={0.15} delay={100}>
                            <div className={styles.cardProgs}>
                                <div className={styles.imgBox}>
                                    <img src={python} alt="" height={150}/>
                                </div>
                                <h2><IntlMessage id={"pythonDev"}/></h2>
                            </div>
                        </Fade>
                    </Col>
                    <Col xl={8} md={12} sm={24} xs={24}>
                        <Fade triggerOnce={true} cascade damping={0.15} delay={200}>
                            <div className={styles.cardProgs} style={{background: '#110BFF', color: 'white'}}>
                                <div className={styles.imgBox}>
                                    <img src={java} alt="" height={180}/>
                                </div>
                                <h2>
                                    <IntlMessage id={"javaDev"}/>
                                </h2>
                            </div>
                        </Fade>
                    </Col>
                    <Col xl={8} md={12} sm={24} xs={24}>
                        <Fade triggerOnce={true} cascade damping={0.15} delay={300}>
                            <div className={styles.cardProgs}>
                                <div className={styles.imgBox}>
                                    <img src={html} alt="" height={180}/>
                                </div>
                                <h2>
                                    <IntlMessage id={"webBasics"}/>
                                </h2>
                            </div>
                        </Fade>
                    </Col>
                    <Col xl={8} md={12} sm={24} xs={24}>
                        <Fade triggerOnce={true} cascade damping={0.15} delay={400}>
                            <div className={styles.cardProgs} style={{background: '#110BFF', color: 'white'}}>
                                <div className={styles.imgBox}>
                                    <img src={bracket} alt="" height={180}/>
                                </div>
                                <h2>
                                    <IntlMessage id={"zeroCode"}/>
                                </h2>
                            </div>
                        </Fade>
                    </Col>
                    <Col xl={8} md={12} sm={24} xs={24}>
                        <Fade triggerOnce={true} cascade damping={0.15} delay={500}>
                            <div className={styles.cardProgs}>
                                <div className={styles.imgBox}>
                                    <img src={data} alt="" style={{height: "auto", width: 170}}/>
                                </div>
                                <h2>
                                    <IntlMessage id={"anDataBasics"}/>
                                </h2>
                            </div>
                        </Fade>
                    </Col>
                </Row>
            </div>
            <Flex justify={"space-around"} gap={20} align={"center"} vertical={size.width <= 1200}>
                <div>
                    <Fade triggerOnce={true} cascade damping={0.15} direction={"left"}>
                        <div style={{fontSize: size.width > 1024 ? 38 : 23, marginLeft: 20}}>
                            <IntlMessage id={"confirmYour"}/>
                        </div>
                    </Fade>
                    <Fade triggerOnce={true} cascade damping={0.15} direction={"right"}>
                        <div style={{
                            fontSize: size.width > 1024 ? 60 : 30,
                            marginLeft: 40,
                            color: clrs.blackerBlue
                        }}
                        >
                            <IntlMessage id={"qualification"}/>
                        </div>
                    </Fade>
                    <Fade triggerOnce={true} cascade damping={0.15} direction={"left"}>
                        <div style={{fontSize: size.width > 1024 ? 30 : 20, textAlign: "center"}}>
                            <IntlMessage id={"offCertificate"}/>
                        </div>
                    </Fade>
                    <Fade triggerOnce={true} cascade damping={0.15} direction={"right"}>
                        <div style={{fontSize: size.width > 1024 ? 30 : 20, textAlign: "center"}}>
                            <IntlMessage id={"from"}/> <span style={{color: clrs.blackerBlue}}><IntlMessage
                            id={"highEdu"}/></span>
                        </div>
                    </Fade>
                    <Fade triggerOnce={true} cascade damping={0.15} direction={"left"}>
                        <div style={{fontWeight: 400, fontStyle: 'italic', marginLeft: 40, paddingRight: 20}}>
                            <IntlMessage id={"conEduActBased"}/><br/> <IntlMessage id={"stateLicence"}/>
                            №KZ67LAA00019559 <IntlMessage id={"from"}/> 18.01.2021.
                        </div>
                    </Fade>


                </div>
                <img
                    src={cert}
                    alt=""
                    width={size.width > 1200 ? 480 : 400}
                />
            </Flex>
        </Flex>
    );
};

export default MainSection;