import React from 'react';
import Header from "../components/Header/Header";
import {Flex} from "antd";
import StatsSection from "./components/StatsSection/StatsSection";
import PartnersSection from "./components/PartnersSection/PartnersSection";
import Footer from "../components/Footer/Footer";
import NewBanner from "./components/Banner/NewBanner";
import MainSection from "./components/Main/MainSection";
import {register} from "swiper/element/bundle";
import {useWindowSize} from "@uidotdev/usehooks";
import {Fade} from "react-awesome-reveal";
import classes from "./components/Banner/Banner.module.css";
import BannerForm from "./components/Banner/BannerForm";

register();
const NewHomePage = () => {
    const size = useWindowSize();
    return (
        <div>
            <Header/>
            <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}} align={"center"} justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical align={"flex-start"} justify={"flex-start"}>
                    <NewBanner/>
                    <MainSection/>
                    {/*<StatsSection/>*/}
                    <PartnersSection/>
                    {
                        size.width <= 1024 &&
                        <Flex style={{width: "100%", position: "relative"}} gap={20} align={"center"} justify={"center"}>
                            <Fade triggerOnce={true} cascade className={classes.fadeBlock}>
                                <BannerForm/>
                            </Fade>
                        </Flex>
                    }

                </Flex>
            </Flex>
            <Footer/>
        </div>
    );
};

export default NewHomePage;